





































































































































































































































































import Vue from 'vue';
import popUpModal from '@/components/form/pop-up-modal.vue';
import IVertragsfaten from '@/model/IVertragsfaten';
import { exportToPDF, saveZusammenfassungPDF } from '@/helper/handle-pdf';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';

const riskTitles = [
  'Entspricht nicht den marktüblichen Standards.',
  'Entspricht teilweise den marktüblichen Standards.',
  'Entspricht den marktüblichen Standards.',
  'Übertrifft teilweise die marktüblichen Standards.',
  'Übertrifft die marktüblichen Standards',
];

export default Vue.extend({
  name: 'individual-insurance-risk',
  mixins: [dataChangeMixin],
  components: {
    popUpModal,
  },
  data() {
    return {
      name: 'individual-insurance-risk',
      key: 'INDIVIDUAL_INSURANCE',
      backUrl: 'individual-insurance-calc',
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
      paramsVertragsdaten: {
        sumInsured: 0,
        sum371: 0,
        paymentMethod: '',
        versicherungssteuer: 0,
        netization: '',
        factorLevel: '',
      },
      schadenverlaufObj: {
        value: 1,
        text: 'Entspricht den marktüblichen Standards',
        reason: '',
      },
      betriebsstundenObj: {
        value: 1,
        text: 'Entspricht den marktüblichen Standards',
        reason: '',
      },
      maintenanceQualitatObj: {
        value: 1,
        text: 'Entspricht den marktüblichen Standards',
        reason: '',
      },
      mengenrabattObj: {
        value: 1,
        text: 'Entspricht nicht den marktüblichen Standards',
        reason: '',
      },
      sonstigesBesichtigungObj: {
        value: 1,
        text: 'Entspricht den marktüblichen Standards',
        reason: '',
      },
      vertragsbezogeneRabatte: [
        [
          {
            value: 1.5,
            text: riskTitles[0],
          },
          {
            value: 1.25,
            text: riskTitles[1],
          },
          {
            value: 1,
            text: riskTitles[2],
          },
          {
            value: 0.9,
            text: riskTitles[3],
          },
          {
            value: 0.8,
            text: riskTitles[4],
          },
        ],
        [
          {
            value: 1.1,
            text: riskTitles[0],
          },
          {
            value: 1.05,
            text: riskTitles[1],
          },
          {
            value: 1,
            text: riskTitles[2],
          },
          {
            value: 0.95,
            text: riskTitles[3],
          },
          {
            value: 0.9,
            text: riskTitles[4],
          },
        ],
        [
          {
            value: 1.2,
            text: riskTitles[0],
          },
          {
            value: 1.1,
            text: riskTitles[1],
          },
          {
            value: 1,
            text: riskTitles[2],
          },
          {
            value: 0.9,
            text: riskTitles[3],
          },
          {
            value: 0.8,
            text: riskTitles[4],
          },
        ],
        [
          {
            value: 1,
            text: riskTitles[0],
          },
          {
            value: 0.975,
            text: riskTitles[1],
          },
          {
            value: 0.95,
            text: riskTitles[2],
          },
          {
            value: 0.925,
            text: riskTitles[3],
          },
          {
            value: 0.9,
            text: riskTitles[4],
          },
        ],
        [
          {
            value: 1.4,
            text: riskTitles[0],
          },
          {
            value: 1.2,
            text: riskTitles[1],
          },
          {
            value: 1,
            text: riskTitles[2],
          },
          {
            value: 0.8,
            text: riskTitles[3],
          },
          {
            value: 0.6,
            text: riskTitles[4],
          },
        ],
      ],
      ergebnisDerBewertung: {
        GesamtergebnisGemabRisikofaktorenbewertung: 0,
      },
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'id'],
  watch: {
    'schadenverlaufObj.value': function (val: any) {
      this.schadenverlaufObj.text = this.getContractRelatedDiscountByValue(0, val);
    },
    'betriebsstundenObj.value': function (val: any) {
      this.betriebsstundenObj.text = this.getContractRelatedDiscountByValue(1, val);
    },
    'maintenanceQualitatObj.value': function (val: any) {
      this.maintenanceQualitatObj.text = this.getContractRelatedDiscountByValue(2, val);
    },
    'mengenrabattObj.value': function (val: any) {
      this.mengenrabattObj.text = this.getContractRelatedDiscountByValue(3, val);
    },
    'sonstigesBesichtigungObj.value': function (val: any) {
      this.sonstigesBesichtigungObj.text = this.getContractRelatedDiscountByValue(4, val);
    },
  },
  methods: {
    getContractRelatedDiscountByValue(index: number, value: any): string {
      return this.vertragsbezogeneRabatte[index].find((itm) => itm.value === value)?.text || '';
    },
    goToBackUrl() {
      this.$emit('go-back-tab', this.backUrl, 'insuranceForms', 2);
    },
    paramsVertragsdatenChange(params: IVertragsfaten) {
      this.paramsVertragsdaten.sumInsured = params.sumInsured;
      this.paramsVertragsdaten.sum371 = params.sum371;
      this.paramsVertragsdaten.paymentMethod = params.paymentMethod;
      this.paramsVertragsdaten.versicherungssteuer = params.versicherungssteuer;
      this.paramsVertragsdaten.netization = params.netization;
      this.paramsVertragsdaten.factorLevel = params.factorLevel;
    },
    onSubmit() {
      const data = {
        paramsVertragsdaten: this.paramsVertragsdaten,
        schadenverlaufObj: this.schadenverlaufObj,
        betriebsstundenObj: this.betriebsstundenObj,
        maintenanceQualitatObj: this.maintenanceQualitatObj,
        mengenrabattObj: this.mengenrabattObj,
        sonstigesBesichtigungObj: this.sonstigesBesichtigungObj,
        ergebnisDerBewertung: {
          GesamtergebnisGemabRisikofaktorenbewertung: this.averageRisk,
        },
      };
      this.$emit('risk-factor-data-change', data);
    },
    setValues(insuranceData: any) {
      const { riskFactorData } = insuranceData;
      if (riskFactorData) {
        this.paramsVertragsdaten = riskFactorData.paramsVertragsdaten;
        this.schadenverlaufObj = riskFactorData.schadenverlaufObj;
        this.betriebsstundenObj = riskFactorData.betriebsstundenObj;
        this.maintenanceQualitatObj = riskFactorData.maintenanceQualitatObj;
        this.mengenrabattObj = riskFactorData.mengenrabattObj;
        this.sonstigesBesichtigungObj = riskFactorData.sonstigesBesichtigungObj;
        this.ergebnisDerBewertung = riskFactorData.ergebnisDerBewertung;
      }
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    downloadPDF() {
      exportToPDF.call(this, 'Zusammenfassung-Maschine-Risikofaktoren.pdf');
    },
    savePdf() {
      saveZusammenfassungPDF.call(this, this.id, 'saveRiskPDF', this.onSubmit);
    },
  },
  computed: {
    averageRisk(): number {
      let value = this.schadenverlaufObj.value * this.betriebsstundenObj.value * this.maintenanceQualitatObj.value * this.mengenrabattObj.value * this.sonstigesBesichtigungObj.value;

      value = value < 0.6 ? 0.6 : value;

      return +value.toFixed(2);
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    if (this.insuranceData) {
      this.setValues(this.insuranceData);
      (this as any).startWatcherActivated();
    }
  },
});
